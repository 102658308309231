.google-calendar-container {
  width: 100%;
  height: 100%;
  position: relative;

  &-modal {
    position: absolute;
    top: 200px;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9;
    background: darkblue;
    width: 700px;
    height: 400px;
    display: none;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-radius: 10px;
    transition-duration: 0.8s;

    &-visible {
      display: flex;
    }

    button,
    input[type='submit'],
    input[type='reset'] {
      background: none;
      color: inherit;
      padding: 0;
      font: inherit;
      cursor: pointer;
      outline: inherit;
    }

    &-form {
      display: flex;
      flex-direction: column;

      &-btn {
        border: 1px solid transparent;
        transition-duration: 0.3s;
        visibility: hidden;
        margin-top: 10px;

        &-visible {
          visibility: visible;
        }

        &:hover {
          border: 1px solid #4eb5f1;
        }
      }

      &-input {
        margin-bottom: 10px;
        width: 300px;
      }

      &-back {
        position: absolute;
        top: 10px;
        left: 10px;
        border: 1px solid #4eb5f1;
        width: 100px;
        border: 1px solid #4eb5f1;

        &:hover {
          opacity: 0.8;
        }
      }
    }
  }

  &-create {
    margin: 20px 30px;
    display: flex;
    justify-content: center;
    align-items: center;

    button,
    input[type='submit'],
    input[type='reset'] {
      background: none;
      color: inherit;
      padding: 0;
      font: inherit;
      cursor: pointer;
      outline: inherit;
    }

    &-button {
      width: 150px;
      background: transparent;
      border: 1px solid #4eb5f1;
      border-radius: 10px;
      height: 40px;
      transition-duration: 0.3s;
      position: absolute;
      left: 40px;

      &:hover {
        background-color: #4eb5f1;
        opacity: 0.7;
      }
    }
  }

  &-list {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-bottom: 30px;

    &-item {
      width: 60%;
      margin-bottom: 10px;
      border-radius: 10px;
      border: 1px solid #4eb5f1;
      display: flex;
      align-items: center;
      justify-content: space-between;
      transition-duration: 0.3s;

      &:hover {
        background-color: #4eb5f1;
        opacity: 0.7;
      }

      &-title {
        margin-left: 10px;
        text-overflow: ellipsis;
        overflow: hidden;
        width: 460px;
        white-space: nowrap;
      }

      &-delete-btn {
        margin-right: 10px;
        width: 50px;
        color: red;
        opacity: 0.6;
        cursor: pointer;
        transition-duration: 0.3s;

        &:hover {
          opacity: 0.3;
        }
      }
    }
  }
}

html {
  box-sizing: border-box;
  height: 100%;
}

.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  height: 40vh;
  position: relative;
  z-index: 0;
}

/* Fixes missing arrow icon */
.DateInput {
  position: static;
}

button.download-btn {
  display: inline-block;
  padding: 0.3em 1.2em;
  margin: 0 0.3em 0.3em 0;
  border-radius: 2em;
  border: none;
  box-sizing: border-box;
  text-decoration: none;
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  color: #ffffff;
  background-color: #4eb5f1;
  text-align: center;
  transition: all 0.2s;
}

button.download-btn:hover {
  background-color: #4095c6;
}

@media all and (max-width: 30em) {
  button.download-btn {
    display: block;
    margin: 0.2em auto;
  }
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

.side-bar {
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  background-color: white;
  width: 250px;
  z-index: 6000;
  transform: translateX(-100%);
  transition: transform 0.5s ease-out;
  overflow-y: auto;
}

.side-bar.open {
  transform: translateX(0);
}

.backdrop {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(34, 34, 34, 0.5);
  z-index: 5000;
}

.white {
  background: white;
}

.dark {
  background: #424242;
  color: #fff;
}

.active {
  background-color: #4eb5f1;
  color: white !important;
  padding-left: 35px;
  padding-right: 35px;
}

.active-user {
  background-color: #3689ba !important;
}
.active-user a {
  color: white !important;
  padding-left: 35px;
  padding-right: 35px;
}

.active-user a:hover {
  background-color: #226287 !important;
}

.auth-but-div {
  display: flex;
  align-items: center;
  padding: 24px;
}

.auth-but {
  text-decoration: none;
  color: white;
  font-weight: 800;
  text-transform: uppercase;
  transition: all 0.5s;
}

.auth-but:hover {
  color: #555;
}

nav {
  display: flex;
  align-items: center;
  width: 100%;
}
nav ul {
  list-style: none;
  text-align: center;
  border-bottom: 1px solid #4eb5f1;
  width: 100%;
  display: flex;
  justify-content: flex-start;
}

nav ul li:last-of-type {
  margin-left: 25px;
  margin-right: 25px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-left: 1px solid #4eb5f1 !important;
  border-right: 1px solid #4eb5f1 !important;
  border-top: 1px solid #4eb5f1 !important;
}

nav ul li:nth-last-child(even) {
  border-left: none;
  border-right: none;
}

nav ul li:nth-last-child(3) {
  border-top-right-radius: 5px;
  border-right: 1px solid #4eb5f1 !important;
}

nav ul li:nth-last-child(2) {
  border: none;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-left: auto;
}

nav ul li:first-of-type {
  border-top-left-radius: 5px;
  border-left: 1px solid #4eb5f1 !important;
}

nav ul li {
  display: inline-block;
  border: 1px solid #4eb5f1;
  border-bottom: none;
  display: flex;
  align-items: center;
}

nav ul li a {
  display: block;
  padding: 15px;
  text-decoration: none;
  color: #4eb5f1;
  font-weight: 600;
  text-transform: uppercase;
  height: 100%;
}

nav ul li a,
nav ul li a:after,
nav ul li a:before {
  transition: all 0.5s;
}

nav ul li a:hover {
  background-color: #4eb5f1 !important;
  color: white !important;
}

@media (max-width: 1099px) {
  nav ul li a {
    padding: 8px;
    font-size: 12px;
  }

  .auth-but {
    font-size: 12px;
  }

  .active {
    padding: 8px 15px;
  }
}

@media (max-width: 840px) {
  nav ul li a {
    padding: 5px;
    font-size: 11px;
  }

  .auth-but {
    font-size: 11px;
  }

  .active {
    padding: 5px 15px;
  }
}

.main-container {
  display: flex;
  justify-content: center;
}

.container,
.users-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0;
}

.users-item {
  text-decoration: none;
  margin: 12px;
  width: 300px;
  padding: 10px;
  border-radius: 20px;
  background: transparent;
  color: #4eb5f1;
  border: 2px solid #4eb5f1;
  display: flex;
  align-items: center;
  transition: all 0.5s;
}

.users-item:hover {
  background: #4eb5f1;
  color: rgba(255, 255, 255, 1);
}

@media (min-width: 768px) {
  .container,
  .users-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 0;
  }
  .main-container {
    display: flex;
    justify-content: unset;
  }
}

@media (min-width: 1024px) {
  .container,
  .users-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 0 40px;
  }
  .main-container {
    display: flex;
    justify-content: unset;
  }
}
